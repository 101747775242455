$(function(){

  //===============================================
  /* トップメインスライド */
  //===============================================
  $('#top_slide').slick({
    autoplay:true,
    dots:true,
    fade:true,
    arrows:false,
    autoplaySpeed: 5000,
    speed: 500,
  });
});